import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import { colors } from '@dqp/common/styles/utilities'
import breakPoints from '@dqp/common/styles/helpers/breakpoints'
import BaseSubjectStat from './BaseSubjectStat/BaseSubjectStat'
import hourGlass from '#images/hourGlass.svg'
import stopWatch from '#images/stopWatch.svg'
import getSafeNumber from '#utils/getSafeNumber'

function TimeStats({
  averageTimePerQuestion,
  latestSubmittedQuestionTime,
}) {
  return (
    <div className='container-md'>
      <div className='row'>
        <div
          className='col-lg-6'
          css={css`
            ${breakPoints({
              marginBottom: [20, 20, 20, 0, 0],
            })}
          `}
        >
          <BaseSubjectStat
            accentColor={colors.black}
            title={`${getSafeNumber(
              latestSubmittedQuestionTime,
            )} secs`}
            subTitle='time on previous questions'
            img={hourGlass}
          />
        </div>
        <div className='col-lg-6'>
          <BaseSubjectStat
            accentColor={colors.black}
            title={`${getSafeNumber(averageTimePerQuestion)} secs`}
            subTitle='average time per question'
            img={stopWatch}
          />
        </div>
      </div>
    </div>
  )
}

TimeStats.propTypes = {
  averageTimePerQuestion: PropTypes.number,
  latestSubmittedQuestionTime: PropTypes.number,
}

TimeStats.defaultProps = {
  averageTimePerQuestion: 0,
  latestSubmittedQuestionTime: 0,
}

export default TimeStats
