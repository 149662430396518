import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import Text from '@dqp/common/components/Text/Text'
import {
  colors,
  spacer,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Button from '@dqp/common/components/Button/Button'
import { navigate } from '@reach/router'
import NotificationWrapper from '../NotificationWrapper/NotificationWrapper'
import rocket from '#images/rocket.svg'

const styles = {
  imgContainer: css`
    background-color: ${colors.purple};
  `,
  img: css`
    margin: 0 40px 0 20px;
    ${breakpoints({
      width: [100, 100, 120, 120, 120],
    })}
  `,
}
function NextQuestionNotification({
  to,
  questionNumber,
  questionCategory,
}) {
  return (
    <NotificationWrapper isDismissible={false} bg={colors.yellow}>
      <div>
        <div className='d-flex align-items-start align-items-md-center flex-column flex-md-row'>
          <img
            src={rocket}
            alt='notes-svg'
            width='120'
            css={styles.img}
            className='d-none d-md-block'
          />
          <div className='d-flex flex-column '>
            <Text
              size='xSmall'
              color={colors.black}
              weight='bold'
              className='align-self-start'
              css={spacer.mrB10}
            >
              NEXT QUESTION
            </Text>
            <Text
              size='xLarge'
              color={colors.black}
              weight='bold'
              className='mt-auto'
            >
              Question {questionNumber}
            </Text>
            <Text color={colors.black}>{questionCategory}</Text>
          </div>
          <Button
            variant='blue'
            size='small'
            inline
            css={spacer.mrT10}
            onClick={() => {
              navigate(to, { state: { questionNumber } })
            }}
            className='ml-md-auto mt-md-auto'
          >
            ANSWER NOW
          </Button>
        </div>
      </div>
    </NotificationWrapper>
  )
}

NextQuestionNotification.propTypes = {
  questionNumber: PropTypes.any.isRequired,
  questionCategory: PropTypes.any.isRequired,
  to: PropTypes.string.isRequired,
}
export default NextQuestionNotification
