// import React, { useState } from 'react'
import React from 'react'
import PropTypes from 'prop-types'
import { spacer } from '@dqp/common/styles/utilities'
import { navigate } from 'gatsby'
// import { pickBy, get } from 'lodash-es'
import { get } from 'lodash-es'
import OverallStats from './OverallStats'
import CategoryPerformanceStats from './CategoryPerformanceStats'
import TimeStats from './TimeStats'
import Header from './Header'
import SeeMoreQuestionsNotification from '#components/Notifications/SeeMoreQuestionsNotification'
// import Filter from '#components/Filter/Filter'
import { useGetSubscriptionData } from '#api/getSubscriptionData'
import Loader from '#components/Loader/Loader'
import Error from '#components/Error/Error'
import { useGetProfile } from '#api/getProfile'
import routes from '#globals/routes'
import NextQuestionNotification from '#components/Notifications/NextQuestionNotification'

const defaultTab = { value: ``, label: `Overview` }

const SubjectDashboard = ({ profileId, subscriptionId }) => {
  const {
    profile,
    loading: profileLoading,
    error: profileError,
  } = useGetProfile({ profileId })
  const {
    subscriptionData,
    loading,
    error,
  } = useGetSubscriptionData({ subscriptionId })
  // const [selectedCategories, setSelectedCategories] = useState({})

  if (loading || profileLoading) {
    return <Loader />
  }
  if (error || profileError) {
    return <Error error={JSON.stringify(error)}>{error}</Error>
  }

  const { subscriptions } = profile.getProfile
  const activeSubscriptions = subscriptions.filter(
    subscription => subscription.is_active,
  )
  const tabs = activeSubscriptions.map(subscription => ({
    value: subscription.id,
    label: subscription.product.label,
  }))
  const onTabClick = tab => {
    if (tab.value) {
      navigate(
        routes.subjectDashboard.getPathFromParams({
          profileId,
          subscriptionId: tab.value,
        }),
      )
    } else {
      navigate(
        routes.overviewDashboard.getPathFromParams({
          profileId,
        }),
      )
    }
  }
  // const allCategories = subscriptionData.getSubscriptionData.categories_performance.map(
  //   c => ({ value: c.id, label: c.categoryName }),
  // )
  // const selectedCategoriesLength = Object.keys(
  //   pickBy(selectedCategories, Boolean),
  // ).length

  // const filteredCategories = subscriptionData.getSubscriptionData.categories_performance.filter(
  //   c => (selectedCategoriesLength ? selectedCategories[c.id] : true),
  // )

  const firstUnattemptedQuestion = subscriptionData.getSubscriptionData.questionsWithNumberAttached.find(
    question => {
      const wasCorrect = get(question, 'attempt.was_correct', null)
      return wasCorrect == null
    },
  )

  const questionDashboardRoute = routes.questionDashboard.getPathFromParams(
    {
      profileId,
      subscriptionId,
    },
  )
  return (
    <>
      <Header
        onTabClick={onTabClick}
        initialSelectedTab={tabs.find(
          tab => tab.value === subscriptionId,
        )}
        tabs={
          activeSubscriptions.length === 1
            ? tabs
            : [defaultTab, ...tabs]
        }
        profileName={profile.getProfile.name}
        profileId={profileId}
        subscriptionId={subscriptionId}
      />
      {firstUnattemptedQuestion && (
        <div className='container-md' css={spacer.mrB20}>
          <NextQuestionNotification
            questionNumber={firstUnattemptedQuestion.question.number}
            questionCategory={
              firstUnattemptedQuestion.question.category.name
            }
            to={questionDashboardRoute}
          />
        </div>
      )}
      {/* <div className='container-md'>
        <Filter
          selectedCategories={selectedCategories}
          setSelectedCategories={setSelectedCategories}
          trigger='ALL CATEGORIES'
          categories={allCategories}
          css={spacer.mrB20}
        />
      </div> */}
      <OverallStats
        correctAnswers={
          subscriptionData.getSubscriptionData.performance
            .good_answers
        }
        incorrectAnswers={
          subscriptionData.getSubscriptionData.performance.bad_answers
        }
        totalQuestions={
          subscriptionData.getSubscriptionData.total_product_questions
        }
        completedQuestions={
          subscriptionData.getSubscriptionData.performance
            .answered_questions
        }
      />
      <div css={spacer.mrB40} />
      <CategoryPerformanceStats
        themeColor={
          subscriptionData.getSubscriptionData.subscription.product
            .color
        }
        // categoriesData={filteredCategories}
        subscriptionData={subscriptionData}
      />
      <div css={spacer.mrB40} />
      <TimeStats
        averageTimePerQuestion={
          subscriptionData.getSubscriptionData.performance
            .average_time_per_question
        }
        latestSubmittedQuestionTime={
          subscriptionData.getSubscriptionData.performance
            .latest_submitted_question_time
        }
      />
      <div css={spacer.mrB40} />

      <div className='container-md'>
        <SeeMoreQuestionsNotification to={questionDashboardRoute} />
      </div>
    </>
  )
}

SubjectDashboard.propTypes = {
  profileId: PropTypes.string.isRequired,
  subscriptionId: PropTypes.string.isRequired,
}
export default SubjectDashboard
