import React from 'react'
import PropTypes from 'prop-types'
import { css } from '@emotion/core'
import Text from '@dqp/common/components/Text/Text'
import {
  colors,
  spacer,
  breakpoints,
} from '@dqp/common/styles/utilities'
import Title from '@dqp/common/components/Title/Title'
import { LinkBtn } from '@dqp/common/components/Button/Button'
import NotificationWrapper from '../NotificationWrapper/NotificationWrapper'
import notes from '#images/notes.svg'

const styles = {
  container: css``,
  img: css`
    margin: 0 50px;
    margin-right: 60px;
    margin-bottom: 20px;
    ${breakpoints({
      width: [100, 100, 120, 120, 120],
    })}
  `,
}
function SeeMoreQuestionsNotification({ to }) {
  return (
    <NotificationWrapper isDismissible={false} bg={colors.purple}>
      <div className='d-flex align-items-center flex-column flex-lg-row'>
        <img
          src={notes}
          alt='notes-svg'
          width='120'
          css={styles.img}
        />
        <div className='text-center text-lg-left'>
          <Title
            size='large'
            color={colors.white}
            weight='bold'
            css={spacer.mrB20}
          >
            I’m ready to tackle more!
          </Title>
          <LinkBtn variant='black' size='medium' inline to={to}>
            <Text
              as='span'
              size='small'
              weight='bold'
              color={colors.white}
            >
              SEE MORE QUESTIONS
            </Text>
          </LinkBtn>
        </div>
      </div>
    </NotificationWrapper>
  )
}

SeeMoreQuestionsNotification.propTypes = {
  to: PropTypes.string.isRequired,
}
export default SeeMoreQuestionsNotification
