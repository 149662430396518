import { css } from '@emotion/core'
import React from 'react'
import PropTypes from 'prop-types'
import {
  colors,
  spacer,
  txtColor,
} from '@dqp/common/styles/utilities'
import Text from '@dqp/common/components/Text/Text'
import ProgressBar from '#components/ProgressBar/ProgressBar'
import calculatePercentage from '#utils/calculatePercentage'

const styles = {
  progressBarWrapper: css`
    position: relative;
  `,
  progressBarOverlayText: css`
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translate(0%, -50%);
    text-shadow: 0px 0px 3px rgba(255, 255, 255, 0.5);
    text-transform: uppercase;
  `,
}

function CategoryPerformance({
  totalQuestions,
  categoryName,
  totalAttemptedQuestions,
  themeColor,
  correctAnswers,
  incorrectAnswers,
  ...rest
}) {
  const percentageCompleted = calculatePercentage(
    totalAttemptedQuestions,
    totalQuestions,
  )
  console.info(rest)
  return (
    <div className='d-flex flex-column'>
      <Text
        color={colors.black}
        size='medium'
        weight='bold'
        css={spacer.mrB10}
      >
        {categoryName}
      </Text>
      <div css={[styles.progressBarWrapper, spacer.mrB5]}>
        <ProgressBar
          barColor={themeColor}
          progress={percentageCompleted}
        />
        <Text
          weight='bold'
          size='small'
          color={colors.grey}
          css={styles.progressBarOverlayText}
        >
          {totalAttemptedQuestions}/{totalQuestions}&nbsp;completed (
          {percentageCompleted}%)
        </Text>
      </div>
      <Text
        weight='bold'
        size='small'
        // className='d-flex justify-content-between align-items-center'
        color={colors.grey}
        css={[spacer.mrL15]}
      >
        <span css={txtColor.green}>
          {correctAnswers}&nbsp;Correct
        </span>
        <span css={spacer.padLR5}>|</span>
        <span css={txtColor.red}>
          {incorrectAnswers}&nbsp;Incorrect
        </span>
      </Text>
    </div>
  )
}
CategoryPerformance.propTypes = {
  categoryName: PropTypes.string.isRequired,
  totalQuestions: PropTypes.number.isRequired,
  totalAttemptedQuestions: PropTypes.number.isRequired,
  correctAnswers: PropTypes.number.isRequired,
  incorrectAnswers: PropTypes.number.isRequired,
  themeColor: PropTypes.string.isRequired,
}
export default CategoryPerformance
