/* eslint-disable react/prop-types */
import React, { useState } from 'react'
import { css } from '@emotion/core'
import PropTypes from 'prop-types'
import Text from '@dqp/common/components/Text/Text'
import {
  mixins,
  colors,
  spacer,
  misc,
} from '@dqp/common/styles/utilities'
import breakPoints from '@dqp/common/styles/helpers/breakpoints'
import { pickBy } from 'lodash-es'
import CategoryPerformance from './CategoryPerformance/CategoryPerformance'
import Filter from '#components/Filter/Filter'

const styles = {
  categoryPerformanceFilters: css`
    .dropdown__content {
      ${mixins.respondToMax(misc.viewport['c-vp'])} {
        right: auto;
      }
    }
  `,
}

function CategoryPerformanceStats({ themeColor, subscriptionData }) {
  const [selectedCategories, setSelectedCategories] = useState({})

  const selectedCategoriesLength = Object.keys(
    pickBy(selectedCategories, Boolean),
  ).length

  const filteredCategories = subscriptionData.getSubscriptionData.categories_performance.filter(
    c => (selectedCategoriesLength ? selectedCategories[c.id] : true),
  )

  const allCategories = subscriptionData.getSubscriptionData.categories_performance.map(
    c => ({ value: c.id, label: c.categoryName }),
  )

  return (
    <div className='container-md'>
      <div className='row justify-content-sm-between align-items-center'>
        <div className='col-12 col-sm-7'>
          <Text
            size='xLarge'
            weight='bold'
            color={colors.black}
            css={spacer.mrB20}
          >
            Category progress
          </Text>
        </div>
        <div className='col-12 col-sm-5'>
          <Filter
            selectedCategories={selectedCategories}
            setSelectedCategories={setSelectedCategories}
            trigger='ALL CATEGORIES'
            categories={allCategories}
            css={[spacer.mrB20, styles.categoryPerformanceFilters]}
            className='d-block d-sm-flex'
          />
        </div>
      </div>
      <div
        css={css`
          box-shadow: ${misc.boxShadow.default};
          border-radius: ${misc.borderRadius.md};
          background-color: ${colors.white};

          ${breakPoints({
            padding: [
              `0 0`,
              `20px 30px`,
              `20px 30px`,
              `20px 50px`,
              `20px 50px`,
            ],
          })};
          ${spacer.mrB20}
        `}
      >
        <div className='row'>
          {filteredCategories.map(categoryData => (
            <div
              key={categoryData.id}
              className='col-lg-6'
              css={[spacer.mrBT20, spacer.mrLR0]}
            >
              <div css={spacer.mrLR10}>
                <CategoryPerformance
                  {...categoryData}
                  themeColor={themeColor}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

CategoryPerformanceStats.propTypes = {
  themeColor: PropTypes.string.isRequired,
}

export default CategoryPerformanceStats
